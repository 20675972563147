.o-voucher-code {
    margin: 0 auto $space * 8;

    &__header {
        text-align: center;
        margin-bottom: $space * 4;
    }
    &__container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px dashed;
        width: 100%;
        padding: $space * 4 0;

        &.--default {
            color: $grey--darker;
            border-color: $grey;
            cursor: copy;

            .fa-check {
                display: none;
            }
        }

        &.--action {
            color: $green;
            border-color: $green;

            .o-voucher-code__icon-container {
                transform: translateY(55%);
            }

            .o-voucher-code__icon {
                display: none;
            }
        }
    }

    &__icon-container {
        position: absolute;
        right: $space * 4;
        top: 0;
        transform: translateY(50%);

        .fa-check {
            font-size: $font-size--xlarge;
        }
    }

    &__additional-text {
        text-align: center;
        margin-top: $space * 2;

        &-item {
            &::before {
                position: relative;
                content: '■';
                font-family: $font-family--font-awesome;
                left: 0;
                color: $grey--dark;
                padding-right: $space;
            }
        }

        &-item:first-child {
            margin-bottom: $space;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1139";