@include media(xxl) {
    .t-product-highlight {
        &__sub-contents {
            display: flex;
        }

        &__sub-content {
            &:first-child {
                margin-right: $space * 6;
            }
        }

        &__header {
            margin-bottom: $space * 14;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1421";