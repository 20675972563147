.cms-banner {
    margin-top: $space * 2;

    .alert + & {
        margin-top: $space * 3;
    }

    &__container .slider__content {
        margin-left: -$space * 2;
        margin-right: -$space * 2;
    }

    &__link-text {
        display: flex;
        height: 40px;
        padding: 0 ($space * 3);
        justify-content: space-between;
        align-items: center;
        font-weight: $font-weight--bold;

        &::after {
            content: $fa-var-chevron-right;
            font-family: FontAwesome;
        }
    }

    a {
        display: block;
        width: 100%;
        box-shadow: $shadow6;

        img {
            float: left;
            width: 100%;
        }

        &::after {
            content: "";
            display: table;
            clear: both;
        }

        &:hover {
            box-shadow: $shadow2;
        }
    }

    &--big,
    &--medium {
        margin-top: 0;
        margin-left: -$space * 2;
        margin-right: -$space * 2;
    }

    &--medium {
        img {
            max-height: 233px;
        }
    }

    &--small {
        padding: 0 ($space * 2);
        min-width: 248px;
    }

    &--small + &--small {
        margin-left: -$space * 2;
    }

    @include media(md) {
        &--small {
            min-width: 400px;
        }
    }

    @include media(lg) {
        &--big,
        &--medium {
            margin-top: $space * 4;
        }

        &--big,
        &--medium,
        &__container,
        &__container .slider__content {
            margin-left: 0;
            margin-right: 0;
        }

        &--small {
            padding: 0;
            min-width: 0;
        }

        &--small + &--small {
            margin-left: $space * 2;
        }

        &__link-text {
            display: none;
        }

        &__container .slider__content {
            overflow: visible;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1458";