.a-play-button {
    background-color: #c50e11;
    border-bottom-right-radius: $border-radius;
    border-top-right-radius: $border-radius;
    border: 0;
    color: #fff;
    font-weight: $font-weight--bold;
    height: 32px;
    padding-left: 32px;
    position: relative;
    width: 130px;

    &::before {
        background-color: #3a3a39;
        border-bottom-left-radius: $border-radius;
        border-right: solid 2px #fff;
        border-top-left-radius: $border-radius;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        top: 0;
        width: 32px;
    }

    &::after {
        content: $fa-var-caret-right;
        font-family: $font-family--font-awesome;
        font-size: 25px;
        left: 4px;
        position: absolute;
        top: 8px;
    }
}

;@import "sass-embedded-legacy-load-done:957";