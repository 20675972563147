/* ------------------------------------ *\
    MARQUEE -- CAROUSEL
\* ------------------------------------ */

.marquee__container { overflow: hidden; }

.marquee__items {
    position: relative;
    left: 0;
    transition: left 0.3s $transition-ease;
}

;@import "sass-embedded-legacy-load-done:338";