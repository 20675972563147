.o-customers-like {

    &.-product-review {
        border-bottom: 1px solid $grey;
    }

    &__title,
    &.-product-desc h2 {
        margin-top: $space * 4;
        margin-bottom: $space * 3;
    }

    &__chips-container {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        margin-bottom: $space * 3;
    }

    &__chip-item {
        margin-right: $space * 2;
        &:last-child {
            margin-right: 0;
        }
    }

    &__chip-content-title {
        margin-top: $space * 2;
        margin-bottom: $space * 2;
    }

    &__chip-content-item {
        display: block;
        margin-bottom: $space * 3;
        &.collapsed {
            display: none;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1409";