.cart-v2 {
    .paypal-express-checkout-hint {
        text-align: left;
        font-size: $font-size--small;
        color: $theme-color;
        cursor: default;
        margin: ($space * 2) 0 ($space * 4);
        position: relative;

        @include media(lg) {
            margin-top: 0;
        }

        &__icon {
            font-size: $font-size--regular-plus;
            margin-right: $space;
        }

        &__tooltip {
            min-width: 275px;
        }
    }

    .haulier-phone-hint {
        position: relative;
        color: $theme-color;
        margin-left: $space;
        text-align: center;

        &__icon {
            font-size: $font-size--medium;
        }

        &__tooltip {
            min-width: 275px;
            left: -240px;
            font-weight: $font-weight--base;

            @include media(xl) {
                left: -15px;
            }
        }

        .tooltip::before {
            left: calc(91% - 1px);

            @include media(xl) {
                left: calc(8% - 1px);
            }
        }

        .tooltip::after {
            left: calc(91% - 2px);

            @include media(xl) {
                left: calc(8% - 2px);
            }
        }
    }
    .paypal-express-checkout-button.button {
        width: 100%;
        padding: 0 ($space * 4);
        margin: 0 0 ($space * 2) 0;
        display: flex;
    }
}

;@import "sass-embedded-legacy-load-done:839";