@include media(md) {
    .o-header-slider {
        &__brand-logo-wrapper {
            left: $space * 8;
            width: 120px;
            height: 60px;
        }

        &__banner-img {
            aspect-ratio: 3 / 1;
        }

        .o-eye-catcher {
            bottom: $space * 8;
        }

        &__remaining-days {
            @include text-variant(medium-plus);
            margin-bottom: $space;
        }

        &__campaign-title {
            margin-bottom: $space * 6;
        }

        &__btn {
            width: 300px;
            height: 48px;
        }

        &__bottom-container {
            aspect-ratio: 15 / 7;
        }

        &__bottom-container-inner {
            padding: 0 $space * 8;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1146";