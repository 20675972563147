@include media(xxl) {
    .o-expandable-product-list {
        &__products {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
        }

        &__product-container:nth-child(2n + 7) {
            display: block;
        }

        &__product-container:nth-child(2n + 9) {
            display: none;
        }

        &__product-container.-expanded:nth-child(2n + 9) {
            display: block;
        }

        &__cta.-show-more {
            margin-top: $space * 12;
            padding-bottom: $space * 14;
            margin-bottom: $space * 14;
        }

        &__cta.-show-all {
            margin-top: $space * 12;
            padding-top: $space * 14;
            margin-bottom: $space * 14;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1166";