$buttonOverlap: 5px;
$transitionSpeed: 0.4s;
$switchHeight: 16px;
$buttonSize: 20px;

.switch {
    display: inline-block;
    height: $switchHeight;
    margin: 0 ($space + $buttonOverlap);
    position: relative;
    width: 35px;

    &__title {
        font-weight: $font-weight--bold;
        text-align: left;
    }

    &__slider {
        background-color: $grey;
        border-radius: calc($switchHeight / 2);
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        cursor: pointer;
        position: absolute;

        &::before {
            background-color: $grey--light;
            border-radius: 50%;
            box-shadow: $shadow1;
            bottom: -1px;
            content: "";
            height: $buttonSize;
            left: -$buttonOverlap;
            position: absolute;
            transition: $transitionSpeed;
            width: $buttonSize;
        }
    }

    &__text.active {
        font-weight: $font-weight--bold;
        color: $theme-color;
    }

    &__input {
        display: none;
    }

    &__input:checked + &__slider {
        &::before {
            transform: translateX(#{$buttonSize + $buttonOverlap});
        }

        background-color: $blue;

        .form & {
            background-color: $blue;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1045";