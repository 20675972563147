@include media(xl) {
    .recycling-images {
        &__container {
            margin: ($space * 8) 0 ($space * 14);
        }

        &__image {
            margin-right: $space * 20;

            &:last-child {
                margin-right: 0;
            }

            img {
                max-height: $space * 22;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1339";