$border-gray-1: 1px solid $grey;

.o-layover-menu {
    &__trigger {
        width: max-content;
        cursor: pointer;
    }

    &__wrapper {
        position: fixed;
        top: 0;
        right: 0;
        width: 0;
        height: calc(var(--vh, 1vh) * 100 - 124px);
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.3);
        transition: 0.1s $transition-ease;
        z-index: $z5;
    }

    &__wrapper.show {
        width: 100%;
    }

    &__panel {
        width: calc(100% - 48px);
        margin-left: 48px;
        background-color: white;
    }

    &__header {
        height: 48px;
        display: flex;
        border-bottom: $border-gray-1;
    }

    &__close-button {
        width: 48px;
        height: 48px;
        border: none;
        cursor: pointer;
        line-height: 48px;
        text-align: center;
        margin-left: -48px;
        color: $theme-color;
        font-size: $font-size--icon;
        background-color: $grey--light;
        border-bottom: $border-gray-1;
    }

    &__title {
        width: 100%;
    }

    &__content {
        top: 48px;
        border-left: $border-gray-1;
        height: calc(var(--vh, 1vh) * 100 - 267px);
        overflow-y: scroll;
    }

    &__footer {
        height: 70px;
        padding: 3 * $space;
        top: calc(var(--vh, 1vh) * 100 - 267px);
        border-left: $border-gray-1;
        box-shadow: 0 -6px 6px rgba(0, 0, 0, 0.19);
    }
}

;@import "sass-embedded-legacy-load-done:1488";