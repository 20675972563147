@include media(md) {
    .navigation-mobile {
        .a-switch__label {
            max-width: 100%;
            margin-bottom: 0;
            align-self: center;
        }
        .a-switch-with-text--large {
            margin-right: 0;
            margin-left: auto;
        }

        .m-pictures-menu__item {
            width: auto;
            margin: 0 ($space * 3);

            &:nth-child(even) {
                margin-right: $space * 3;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:880";