@include media(lg) {
    .o-eye-catcher {
        &--full-blue,
        &--half-blue {
            width: 130px;
            height: 120px;
        }
        &--full-blue {
            background-image: url(https://media.contorion.de/content/eyecatcher/eyecatcher-full-blue.png);
            padding: $space * 3;
        }
        &--half-blue {
            background-image: url(https://media.contorion.de/content/eyecatcher/eyecatcher-half-blue.png);
        }
        &--discount {
            padding: ($space * 3) 0;
        }
        &--discount_code {
            padding: $space 0;
        }
        &--discount_code_brand {
            padding: ($space * 2) 0;
        }
    }
    .o-eye-catcher .o-eye-catcher {
        &__hyphen-preceding-text,
        &__primary-text {
            &--text {
                font-size: $font-size--large;
                line-height: $line-height--large-plus;
                @include multi-line-ellipsis (110px, 3);
            }
            &--discount,
            &--discount_code {
                font-size: $font-size--giant;
                line-height: $line-height--giant;
            }
            &--discount_code_brand {
                font-size: $font-size--xlarge-plus;
                line-height: $line-height--xlarge;
            }
        }
        &__pre-text,
        &__trailing-text,
        &__asterisk {
            font-size: $font-size--xlarge;
            line-height: $line-height--xlarge;
            &--discount_code_brand, {
                font-size: $font-size--large;
                line-height: $line-height--large;
            }
        }
        &__primary-sub-text {
            @include ellipsis (125px);
        }
        &__primary-sub-text {
            font-size: $font-size--regular-plus;
            line-height: $line-height--regular;
        }
        &__sec-text {
            &--discount_code_brand,
            &--discount_code {
                padding-top: $space;
            }
        }
        &__discount-text,
        &__action-text {
            font-size: $font-size--medium-plus;
            line-height: 26px;

        }
    }
}

;@import "sass-embedded-legacy-load-done:1373";