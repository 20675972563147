//Refactored Version: badges.atom.scss
.tag {
    background-color: $grey--dark;
    border-radius: $border-radius;
    color: #fff;
    display: inline-block;
    font-size: $font-size--small;
    font-weight: $font-weight--bold;
    padding: 2px $space;
    white-space: nowrap;

    small {
        font-weight: $font-weight--base;
    }

    &--pending {
        background-color: $theme-color;
    }

    &--closed {
        background-color: $grey--light;
        color: $grey--dark;
    }

    &--success {
        background-color: $color--success;
    }

    &--cancelled {
        background-color: $color--cancelled;
    }

    &--warning {
        background-color: $orange;
    }
}


;@import "sass-embedded-legacy-load-done:240";