.product-widget-slider {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    width: 100%;

    .slider__content {
        width: 100%;

        @include media(md) {
            margin-left: 40px;
            margin-right: 40px;
        }
    }

    &.slider--no-buttons .slider__content {
        margin-left: 0;
        margin-right: 0;
    }

    .product-widget {
        max-width: 100%;
        min-width: 70%;
        padding: $space * 2;
    }

    @include media(md) {
        .slider__content {
            margin-left: 40px;
            margin-right: 40px;
        }

        .product-widget {
            max-width: percentage(calc(1 / 2));
            min-width: percentage(calc(1 / 2));
        }
    }

    @include media(lg) {
        .product-widget {
            max-width: percentage(calc(1 / 3));
            min-width: percentage(calc(1 / 3));

            .page-category-listing-categorylisting & {
                max-width: percentage(calc(1 / 2));
                min-width: percentage(calc(1 / 2));
            }

            .page-search-search & {
                max-width: percentage(calc(1 / 3));
                min-width: percentage(calc(1 / 3));
            }
        }
    }

    @include media(xl) {
        .product-widget {
            max-width: percentage(calc(1 / 4));
            min-width: percentage(calc(1 / 4));

            .page-category-listing-categorylisting & {
                max-width: percentage(calc(1 / 3));
                min-width: percentage(calc(1 / 3));
            }

            .page-search-search & {
                max-width: percentage(calc(1 / 4));
                min-width: percentage(calc(1 / 4));
            }
        }
    }

    @include media(xxl) {
        .product-widget {
            max-width: percentage(calc(1 / 5));
            min-width: percentage(calc(1 / 5));

            .page-category-listing-categorylisting &,
            .page-search-search & {
                max-width: percentage(calc(1 / 4));
                min-width: percentage(calc(1 / 4));
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:524";