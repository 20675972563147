@include media(md) {
    .ca-content--plus {
        &__points-overview {
            .panel__body {
                padding-top: $space * 3;
            }
        }

        &__total {
            text-align: left;
            justify-content: unset;
        }

        &__points-info {
            width: auto;
            padding-right: $space * 8;
        }

        &__mobile-hidden {
            display: unset;
        }
    }
}

;@import "sass-embedded-legacy-load-done:741";