@include media(md) {
    .o-column-block {
        width: 45%;

        &:nth-child(2n -1) {
            margin-right: $space * 8;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1105";