/* ------------------------------------ *\
    MEDIA QUERIES
\* ------------------------------------ */

@mixin media($breakpoint-name) {
    @media (min-width: #{map_get($grid-breakpoints, $breakpoint-name)}) { @content; }
}

@mixin input($modality) {
    @if ($modality == hoverable) {
        @media ((hover: hover)) {
            @content;
        }
    }
}

@mixin generate-breakpoint-prefixes($class-name, $short-name: false) {
    $concat: if($short-name == true, '', '_');

    .#{$class-name} { @content; }

    @each $breakpoint-name, $breakpoint-value in $grid-breakpoints {
        @include media($breakpoint-name) {
            .#{$breakpoint-name}#{$concat}#{$class-name} { @content; }
        }
    }
}

/* ------------------------------------ *\
    BORDER
\* ------------------------------------ */

@mixin border($where: null, $width: 1px, $style: solid, $color: $grey, $important: false) {
    $position: (top, right, bottom, left);

    @if ($width == 0) {
        $style: null;
        $color: null;
    }

    @if ($important == true) {
        @if (index($position, $where)) {
            border-#{$where}: $width $style $color;
        } @else {
            border: $width $style $color;
        }
    } @else {
        @if (index($position, $where)) {
            border-#{$where}: $width $style $color;
        } @else {
            border: $width $style $color;
        }
    }
}

/* ------------------------------------ *\
    TEXT
\* ------------------------------------ */

@mixin truncate-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

@mixin line-clamp($line: 1) {
    display: -webkit-box;
    word-wrap: break-word;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
}

@mixin multi-line-ellipsis ($width, $line) {
    word-wrap: break-word;
    display: -webkit-box;
    max-width: $width;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin text-variant($size) {
    font-size: #{map_get($font-sizes, $size)};
    line-height: #{map_get($line-heights, $size)};
}

@mixin button-text-variant($size) {
    font-size: #{map_get($button-font-sizes, $size)};
    line-height: #{map_get($button-line-heights, $size)};
}

/* ------------------------------------ *\
Layout
\* ------------------------------------ */
@mixin grow-outside-container {
    width: 100vw;
    margin-left: calc(-1 * ((100vw - 100%) / 2));
    margin-right: calc(-1 * ((100vw - 100%) / 2));
}

;@import "sass-embedded-legacy-load-done:61";