.search-feedback {
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;

    .loading-icon {
        display: none;
    }

    label.required::after {
        content: none;
    }

    &.inProgress {
        .loading-icon {
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            font-size: 36px;
        }
    }

    .icon--extremely-unhappy,
    .icon--unlucky,
    .icon--neutral,
    .icon--happy,
    .icon--very-happy {
        font-size: 40px;
        color: $grey--medium;

        &:hover {
            color: $blue;
        }
    }

    .extremely-unhappy,
    .unlucky,
    .neutral,
    .happy,
    .very-happy {
        font-size: 40px;
    }

    .active {
        span {
            color: $blue;
        }
    }

    &__select {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        &--title {
            display: flex;
            margin-bottom: 40px;
            justify-content: center;
            font-size: $font-size--large;
            font-weight: $font-weight--bold;
            width: 100%;
            line-height: $line-height--large;
        }
    }

    &__reason {
        margin-bottom: 10px;
        font-weight: $font-weight--bold;
        margin-top: 50px;

        &--text {
            height: 150px;
        }
    }

    &__value {
        display: none;

        & + span {
            display: none !important;
        }
    }

    &__confirm {
        text-align: center;
        width: 45%;
        margin-left: auto;
        margin-right: auto;

        &--icon {
            font-size: 40px;
            color: $blue;
        }

        &--title {
            font-size: $font-size--large;
            margin-bottom: 20px;
            margin-top: 40px;
            line-height: $line-height--large;
        }
    }

    &__step2 {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }

    &__submit {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        margin-bottom: 40px;
        margin-top: 30px;
    }

    &__privacy {
        display: flex;
        margin-top: $space * 8;
        max-width: 523px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        &--link {
            display: contents;
            font-weight: $font-weight--bold;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:929";