//Refactors: styles/v1/components/_product-widget.scss

/* ------------------------------------ *\
    PRODUCT WIDGET - LG
\* ------------------------------------ */
@include media(lg) {
    .o-product-widget-v2 {
        &__product-realtions {
            flex-wrap: nowrap;
        }

        &__data--list-view {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        &__info--list-view {
            max-width: percentage(calc(2 / 3));
            padding: 0 ($space * 2);
        }

        &__buy-block--list-view {
            justify-content: flex-end;
            width: percentage(calc(1 / 3));
        }

        .o-product-widgets__item {
            .layout-icon & {
                flex-direction: column;
                justify-content: space-between;
            }
        }

        .o-product-widget__footer {
            .product-widget-slider & {
                padding-bottom: 48px;
            }

            & > .button {
                padding-top: 7px;
            }
        }
    }
}


;@import "sass-embedded-legacy-load-done:1249";