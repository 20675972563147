.product-configurator {
    .filter {
        position: relative;
        display: inline-block;
        .btn {
            line-height: 20px;
        }
    }

    .filter .tooltip {
        width: 400px;
        display: none;
        position: absolute;
        left: -180px;
        overflow: visible !important;
    }

    .filter:hover .tooltip {
        display: block;
    }

    .product-configurator__content {
        overflow: visible;
    }

    .collapsed {
        overflow: hidden;
    }

    &__sort-select {
        max-width: 200px;
    }
}

;@import "sass-embedded-legacy-load-done:441";