@include media(xxl) {
    .o-main-navigation {
        &__container {
            display: grid;
            column-gap: 14 * $space;
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

;@import "sass-embedded-legacy-load-done:1129";