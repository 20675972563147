.o-product-widget-large {
    display: grid;
    grid-row-gap: 4 * $space;
    grid-column-gap: 4 * $space;
    width: 100%;
    height: fit-content;
    line-height: normal;

    &.-tile-desktop-view {
        padding: 2 * $space;
    }

    &.-list-view {
        padding: 4 * $space;
        grid-template-columns: $image-size-ml 1fr 160px;
        grid-template-rows: repeat(4, auto);
    }

    &.-tile-view {
        grid-template-columns: 150px 1fr;
        padding: 4 * $space 2 * $space;
    }

    &__image-container {
        position: relative;
        display: flex;
        justify-content: center;

        &.-list-view {
            grid-column: 1;
            grid-row: 2 / 4;
        }

        &.-tile-view {
            grid-row: 2 / 4;
            grid-column: 1;
            flex-direction: column-reverse;
            justify-content: flex-end;
        }
    }

    &__image {
        display: block;
        top: 0;
        left: 0;

        &.-tile-desktop-view {
            aspect-ratio: 1 / 1;
            width: 100%;
        }

        &.-list-view {
            width: $image-size-ml;
            height: $image-size-ml;
        }

        &.-tile-view {
            width: 150px;
            height: 150px;
        }

        &.-faded {
            opacity: 0.4;
        }
    }

    &__badges {
        display: flex;
        width: 100%;

        &.-list-view {
            display: none;
        }

        &.-tile-desktop-view {
            justify-content: space-between;
            flex-direction: row;
            position: absolute;
            top: 0;
        }

        &.-tile-view {
            flex-direction: column-reverse;
            justify-content: flex-start;
            gap: $space;
        }
    }

    &__product-details {
        &.-tile-view,
        &.-list-view {
            grid-row: 2;
            grid-column: 2;
        }
    }

    &__product-details {
        &.-list-view {
            grid-row: 2;
        }
    }

    &__product-name {
        &.-tile-desktop-view {
            display: -webkit-box;
            overflow: hidden;
            overflow-wrap: anywhere;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            height: 2 * $line-height--regular;
        }
    }

    &__rating {
        margin-top: 2 * $space;
        height: $font-size--medium;

        &.-hidden {
            display: none;
        }
    }


    &__delivery {
        &.-tile-view {
            grid-row: 3;
            grid-column: 2;
        }

        &.-tile-desktop-view,
        &.-tile-view {
            .-inner {
                display: none;
            }
        }
    }

    &__delivery {
        &.-list-view {
            display: grid;
            grid-row: 3;
            grid-column: 2;
            grid-template-rows: repeat(2, auto);
            align-items: center;
            align-content: space-evenly;
        }
    }

    &__price-tag {
        &.-list-view {
            grid-row: 3 / 4;
            grid-column: 3;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        &.-tile-view {
            grid-row: 4;
            grid-column: 2;
        }
    }

    &__retail-price {
        &.-tile-desktop-view {
            height: 18px;
        }
    }

    &__current-price {
        margin-bottom: $space;
    }

    &__vat_price_per_piece {
        &.-list-view {
            display: contents;
        }
    }

    &__variants {
        background-color: $grey--light;
        display: flex;
        justify-content: center;
        padding: $space 8px;

        &.-list-view {
            width: fit-content;
            height: 25px;
        }

        &.-tile-view {
            grid-row: 4;
            grid-column: 1 / 3;
        }

        &.-inner {
            width: fit-content;
            height: 25px;
        }

        &.-outer {
            grid-row: 5;
            grid-column: 2;
        }
    }

    &__sponsored {
        height: 15px;
        padding: 0;
        margin-bottom: -10px;
    }

    &.-list-view {
        .o-product-widget-large__variants {
            &.-outer {
                display: none;
            }
        }
    }

    &.-tile-desktop-view {
        .o-product-widget-large__variants {
            &.-outer {
                grid-row: unset;
                grid-column: unset;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1239";