@include media(sm) {
    .t-cart-v2-item {
        &__image {
            min-width: 130px;
            width: 130px;
            height: 130px;
        }

        &__bottom-links {
            justify-content: right;
            flex-wrap: wrap;
        }
    }
}

;@import "sass-embedded-legacy-load-done:859";