.m-shipping-info {
    &__progress-bar-container {
        width: 100%;
        background-color: $grey;
        border-radius: 10px;
        overflow: hidden;
        margin-top: $space * 2;
    }

    &__progress-bar {
        height: 8px;
        background-color: $green;
        width: 0;
        border-radius: 10px;
    }
}

;@import "sass-embedded-legacy-load-done:811";