@include media(xxl) {
    .o-product-highlight {
        &__container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
        &__detail {
            grid-auto-rows: min-content;
            align-self: center;
        }
        &__image-wrapper {
            margin-bottom: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1137";