@include media(md) {
    .o-campaign-card {
        margin-bottom: 10 * $space;
        row-gap: 8 * $space;

        &__image-container {
            --padding: #{ 4 *  $space };
        }

        &__image {
            aspect-ratio: 3 / 2;
        }

        &__brand {
            height: 80px;
        }

        &__details {
            align-items: flex-start;
        }

        &__title {
            margin-bottom: 6 * $space;
        }

        &__cta {
            margin-top: 14 * $space;
            height: 66px;
            padding-left: $space * 10;
            padding-right: $space * 10;

            &-container {
                min-width: 50%;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1083";