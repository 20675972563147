//Refactors: styles/v1/components/_product-widget.scss

/* ------------------------------------ *\
    PRODUCT WIDGET
\* ------------------------------------ */

.o-product-widget {
    display: flex;
    margin-bottom: $space * 2;

    &--list-view {
        align-items: center;
        @include border($where: 'bottom');
        flex-direction: row;
        padding-bottom: $space * 2;
        padding-top: $space;
    }

    &--mini-view {
        flex: 0 0 154px;
        position: relative;
        flex-wrap: wrap;
    }

    &--block-view {
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 ($space * 2);
    }

    .shopping-list-widget [class^="icon--"] {
        font-size: 20px;
        left: 50% !important;
        position: absolute;
        top: 50% !important;
        transform: translate3d(-50%, -50%, 0) !important;
    }

    .shopping-list-widget .tooltip {
        left: auto;
        right: 0;
        top: 43px;
    }

    .shopping-list-widget .tooltip::before {
        left: auto;
        right: 12px;
    }

    .shopping-list-widget .tooltip::after {
        left: auto;
        right: 11px;
    }

    &__data--list-view {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }

    &__data--block-view {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: $space * 2;
        width: 100%;
    }

    &__info--list-view {
        height: auto;
        overflow: auto;
        padding: 0 ($space *3);
    }

    &__info--block-view {
        width: 100%;

        &--name {
            font-weight: $font-weight--bold;
            height: 36px;
            overflow: hidden;
        }
    }

    &__buy-block--list-view {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 0 ($space *3);
    }

    &__buy-block--block-view {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        padding-top: $space * 2;
        width: 100%;
    }

    &__rating {
        height: $space * 3;
        margin-top: $space;
    }

    &__price-per-unit {
        padding-bottom: $space * 2;
    }

    &__delivery-time {
        padding-top: $space;
    }
}

.o-product-widgets__item {
    width: 100%;
    .layout-list & {
        width: 100%;
    }
}

.o-product-widget__image {
    padding: 0;
    position: relative;
    width: 100%;

    .lazy-load-container {
        display: block;
        min-height: 56px;
        overflow: hidden;
        position: relative;
    }

    img {
        display: block;
        left: 0;
        margin: 0 auto;
        max-height: 180px;
        max-width: 180px;
        top: 0;
        width: 100%;
    }

    .slider__content & {
        max-width: 100%;
        min-width: 100%;
    }

    .layout-list & {
        img {
            height: auto;
        }
    }
}

.o-product-widget__footer--list-view {
    max-width: 200px;
    width: 100%;
}

.o-product-widget__footer--block-view {
    width: 100%;
}

.o-product-widget__prices--list-view {
    padding-top: ($space * 2);

    .o-product-widget__price-per-unit,
    .product-widget__prices {
        display: flex;
        flex-direction: column;
    }
}

.o-product-widget__prices--block-view {
    width: 100%;

    .badge {
        display: none;
        position: absolute;
        right: $space * 2;
        top: 0;
    }

    .noprice::before {
        content: "\00a0";
        .layout-list & {
            content: "";
        }
    }

    &-suggestions {
        height: 18px;
    }
}

.o-product-widget__footer {
    .product-widget-slider & {
        padding-bottom: 0;
    }

    & > .button {
        height: $space * 8;
        padding: 9px 8px 8px;
    }
}

;@import "sass-embedded-legacy-load-done:1237";