//TODO: BOSS-21237, BOSS-21422 - Clean up, this is intended for an AB test
.m-product-variants {
    &:hover &__list-container {
        max-height: 200px;
    }

    &__list {
        padding-top: $space * 3;
        list-style: disc;
    }
}

//widget view styles
.layout-icon .m-product-variants {
    width: 100%;
    cursor: pointer;

    &__placeholder {
        height: $font-size--small;
        width: 100%;

        &-empty {
            width: 100%;
            height: 29px;
        }
    }

    &__list {
        list-style-position: outside;
        margin-left: $space * 3;
    }

    &__headline {
        text-align: center;
    }

    &__list-container {
        transition: max-height 0.7s ease-out;
        overflow: hidden;
        height: auto;
        max-height: 0;
        background: #fff;
    }
}

//List view styles
.layout-list .m-product-variants {
    display: flex;
    align-items: flex-start;

    &__headline {
        text-align: center;
        white-space: nowrap;
        padding: 0 $space;
        margin-left: $space * 4;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        padding-top: 0;
        margin-left: $space * 2;
        list-style-position: inside;
    }

    &__list-item {
        white-space: nowrap;
        margin-right: $space * 3;
    }

    &__list-item--first {
        list-style: none;
    }
}

;@import "sass-embedded-legacy-load-done:1209";