@include media(xl) {
    .cart-v2 {
        fieldset {
            @include border($where: top, $color: $grey);
            text-align: center;
            margin: $space * 3 0;

            legend {
                padding: 0 ($space + 3);
                margin: auto;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:841";