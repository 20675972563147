.m-item-prices {
    margin-bottom: $space * 4;

    &__highlighted {
        margin-top: $space;
    }
    &__msrp--hint {
        margin-left: $space;
    }
}

;@import "sass-embedded-legacy-load-done:813";