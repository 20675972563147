.press {
    &__contact {
        margin-top: 30px;
    }

    &__downloads {
        @include border(top, $width: $space * 4, $color: $grey--light);
    }

    &__download {
        position: relative;
        color: #fff;

        &:hover > &--hover {
            opacity: 1;
        }

        &--hover {
            position: absolute;
            opacity: 0;
            text-align: center;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(29, 153, 250, 0.6);
            -webkit-transition: opacity 0.2s ease-out;
            transition: opacity 0.2s ease-out;
        }
    }
}

@include media (md) {
    .press {
        &__contact {
            margin-top: 76px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:407";