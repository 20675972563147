.m-cart-payment-methods {
    &__container {
        background-color: #fff;
        box-shadow: 0 1px 2px 0 #ccc;
        padding: $space * 4;
    }

    &__image {
        display: inline-block;
        margin: ($space * 4) ($space * 4) 0 0;
    }
}

;@import "sass-embedded-legacy-load-done:1452";