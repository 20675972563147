@include media(xxl) {
    .o-product-teaser {
        &__element {
            [class*='link'] {
                @include multi-line-ellipsis(380px, 2);
            }
            &--four {
                width: calc(25% - 10px);
                [class*='link'] {
                    @include multi-line-ellipsis(250px, 2);
                }
            }
            &--six {
                [class*='link'] {
                    @include multi-line-ellipsis(380px, 2);
                }
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1369";