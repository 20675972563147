.o-product-buy-box {
    width: 100%;

    &__content {
        position: relative;
        background-color: $grey--light;
        border-radius: $border-radius;
        padding: $space * 3;
        margin-left: -$space * 2;
        margin-right: -$space * 2;

        @include border('bottom');

        .quantity-field__container {
            position: absolute;
            top: 0;
            right: 0;
            .tooltip { min-width: 100%; }
        }

        .btn--stapler:hover {
            color: #fff;
            border-color: $yellow;
            background-color: $yellow;

            & > .message { display: none; }

            & > .message--hover {
                display: inline-block !important;
                visibility: visible;
            }
        }

        .loading &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.4);
        }
    }

    &__msrp {
        margin-bottom: $space * 2;
        display: flex;
        align-items: center;
    }

    &__discount-label {
        margin-right: $space;
        display: inline-block;
        white-space: nowrap;
    }

    &__shopping-list-widget {
        margin-top: $space * 2;
    }

    &__form-abtest29887 {
        padding: $space * 4 0;
        min-height: 110px;
        background: white;
        position: fixed;
        z-index: $z1 + 1; // fix overlap bewteen sticky and angebot text in product widget
        top: 0;
        left: 0;
        right: 0;

        .button--message__hover {
            display: none !important;
        }

        .button--message__text,
        .button__text {
            display: none;
        }
    }
    @keyframes downTop {
        from {
            opacity: 1;
            top: 0;
            display: block;
        }
        to {
            opacity: 0;
            top: -110px;
        }
    }
    &__form-abtest29887.hide {
        display: none;
        top: -110px;
        animation: 0.3s ease-out 1 downTop;
    }


    @keyframes topDown {
        from {
            opacity: 0;
            top: -110px;
        }
        to {
            opacity: 1;
            top: 0;
        }
    }
    &__form-abtest29887.show {
        top: 0;
        display: block;
        box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.19);
        animation: 0.3s ease-in 1 topDown;
    }

    &__sticky-abtest29887 {
        display: grid;
        column-gap: 40px;
        align-items: center;
    }

    &__cta-abtest29887 {
        width: initial;
    }
}

;@import "sass-embedded-legacy-load-done:1271";