.ca-addresses {
    .ca-subheader {
        height: 60px;
    }

    &--with-addresses {
        .ca-subheader {
            height: 130px;
        }
    }

    &__address {
        .fa {
            color: #607d8b; // special color?
            font-size: $font-size--medium-plus;
            margin-right: $space * 2;
        }
    }

    &__default-actions {
        white-space: nowrap;
    }

    &__link {
        display: flex;
        width: 100%;
        color: $base-text-color;

        &:hover {
            text-decoration: none;
        }

        @include media(md) {
            width: auto;
        }
    }

    &__info-link {
        align-items: center;
        background-color: $theme-color;
        border-radius: 50%;
        color: #fff;
        display: inline-flex;
        height: 24px;
        justify-content: center;
        margin-left: $space;
        width: 24px;

        &:hover {
            background-color: $theme-color;
            text-decoration: none;
        }
    }

    a[disabled] {
        color: $grey--dark;
        pointer-events: none;
    }
}

.customer-account-addresses form label:not(.checkbox):not(.custom-radio) {
    font-weight: $font-weight--bold;
}

.customer-account-addresses form label.custom-radio::after {
    display: none;
}

#all-addresses {
    .ca-addresses__address .fa {
        color: $grey;
    }
}

@include media(xl) {
    .ca-addresses {
        &--with-addresses {
            .ca-subheader {
                height: 60px;
            }
        }
    }
}

//for google place api widget
.pac-container {
    z-index: $z-max;
    font-family: $font-family--regular;
    left: 0 !important;
    top: ($space * 8) !important;

    .pac-item {
        &:hover {
            background-color: $grey--light;
        }
        border: 0;
        padding: $space * 2;
        height: $space * 8;
        line-height: $line-height--regular;
    }

    .pac-icon {
        margin-top: 0;
        margin-right: $space;
    }

    &.pac-logo {
        &::after {
            margin-right: $space * 2;
            margin-bottom: $space * 2;
        }
    }
}

;@import "sass-embedded-legacy-load-done:592";