.m-product-info {
    li {
        &::before {
            content: "■";
            color: $blue;
            margin-right: $space;
        }

        font-size: $font-size--medium;
        line-height: $line-height--large;
        text-indent: -0.95em;
        margin-left: 0.88em;

        &:not(:last-of-type) {
            margin-bottom: 10px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1199";