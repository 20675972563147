.a-brand-logo {
    &__wrapper {
        display: block;
    }

    &__image {
        border-radius: 0 0 2px 2px;
        height: 48px;
        width: auto;
        border: 6px solid #fff;
        background: #fff;
        object-fit: contain;
    }
}

;@import "sass-embedded-legacy-load-done:941";