.a-config-variant-v2 {
    $s2: $space * 2;
    $s3: $space * 3;

    &__input {
        height: 48px;

        &--dropdown {
            background-size: 18px;
        }

        &--button {
            @include border($color: $grey, $width: 1px);
            margin: 0 $s2 $s2 0;
            padding: $s3;
            border-radius: 2px;
            font-size: $font-size--regular-plus;
            font-weight: $font-weight--bold;
            background-color: white;

            &-selected {
                @include border($color: $blue, $width: 2px);
                color: $blue;
                background-color: $blue--light;
            }
        }
    }

    &--button {
        display: flex;
        flex-wrap: wrap;
    }

    &--disabled {
        .a-config-variant-v2__input {
            cursor: not-allowed;
        }

        .a-config-variant-v2__input--dropdown {
            ::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(#fff, 0.5);
                cursor: not-allowed;
            }
            &[disabled] {
                background-size: 18px;
                background-position: calc(100% - 10px) 20px;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1181";