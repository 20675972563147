//Refactors: static/assets/Yves/styles/v2/elements/_tables.scss

.m-table {
    border-collapse: separate;
    border-radius: $border-radius;
    border-spacing: 0;
    width: 100%;
    table-layout: fixed;

    th,
    td {
        padding: $space ($space * 2) $space 0;
    }

    th {
        font-weight: $font-weight--bold;
        text-align: left;
        vertical-align: bottom;
    }

    td {
        vertical-align: middle;
    }

    &--striped,
    &--reverse-striped {
        th,
        td {
            padding: $space * 2;
        }

        .m-table__cell--light-vertical-border {
            @include border($where: 'right', $width: 2px, $color: #fff);

            &:last-child {
                border-right: 0;
            }
        }

        .m-table__cell--dark-vertical-border {
            @include border($where: 'right');

            &:last-child {
                border-right: 0;
            }
        }

        .m-table__cell--dark-horizontal-border {
            @include border($where: 'bottom');
        }

        .m-table__cell--blue-vertical-border {
            @include border($where: 'right', $width: 2px, $color: $blue);
            @include border($where: 'left', $width: 2px, $color: $blue);
        }

        .m-table__cell--blue-top-border {
            @include border($where: 'top', $width: 2px, $color: $blue);
        }

        .m-table__cell--blue-bottom-border {
            @include border($where: 'bottom', $width: 2px, $color: $blue);

        }
    }

    &--striped-v2 {
        td {
            padding: 16px;
            font-size: $font-size--medium;
        }
    }

    &--striped {
        tbody > tr:nth-child(odd) {
            .m-table__cell--default {
                background-color: $grey--light;
            }

            & .m-table__cell--primary {
                background-color: $blue--medium;
            }
        }

        tbody > tr:nth-child(even) {
            & .m-table__cell--primary {
                background-color: $blue--light;
            }
        }
    }

    &--reverse-striped {
        tbody > tr:nth-child(even) {
            .m-table__cell--default {
                background-color: $grey--light;
            }

            & .m-table__cell--primary {
                background-color: $blue--medium;
            }
        }

        tbody > tr:nth-child(odd) {
            & .m-table__cell--primary {
                background-color: $blue--light;
            }
        }
    }

    &--md-compact {
        padding: 0 $space * 2;
    }
}

;@import "sass-embedded-legacy-load-done:1049";