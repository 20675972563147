.o-summary-prices {
    &__text {
        display: flex;
        justify-content: space-between;
        margin: ($space * 2) 0;
    }

    &__total {
        margin-bottom: $space * -2;
    }

    &__section {
        padding: ($space * 2) 0;
        @include border($where: bottom, $color: $grey);

        &:last-child {
            border-bottom: none;
        }
    }
}

.header-summary .o-summary-prices {
    .header-summary-title {
        display: none;
    }

    .header-summary-section {
        padding-bottom: 0;
        border-bottom: none;
    }
}

;@import "sass-embedded-legacy-load-done:1375";